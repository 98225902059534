import { ref } from 'vue'
export default function YearGetList(http, constant, store) {
    const loading = ref(false)
    const yearList = ref([])
    async function getList(year) {
        try {
            loading.value = true

            const response = await http.post(constant.YEAR_GET_LIST, [])
            loading.value = false

            yearList.value = response.data.data.list
            store.commit('YearsModule/SET_YEAR_LIST', yearList.value)
            store.commit('YearsModule/SET_ACTIVE_YEAR', response.data.data.active)
            
        } catch (err) {
            loading.value = false
        }
    }
    return {
        yearList,
        getList
    }
}