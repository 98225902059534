import { ref } from 'vue'
export default function DashboardGetChartData(http, constant) {
    let chart_data_loading = ref(false)
    let chart_data = ref([])

    async function getDashboardChartData(class_id, term_id, year) {
        chart_data_loading.value = true;
        chart_data.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term_id', term_id)
            f.append('year', year)

            const response = await http.post(constant.DASHBOARD_GET_CHART_DATA, f)
            chart_data_loading.value = false
            chart_data.value = response.data.data
            console.log(chart_data)
        } catch (err) {
            chart_data_loading.value = false
        }
    }
    
    return {
        chart_data_loading, chart_data, getDashboardChartData
    }
}