import { ref } from 'vue'
export default function StudentsGetWellbeingData(http, constant) {
    let wellbeing_loading = ref(false)
    let wellbeing_data = ref([])

    async function getWellbeingData(class_id, term_id, year) {
        wellbeing_loading.value = true;
        wellbeing_data.value = []
        try {
            const f = new URLSearchParams()
            f.append('class_id', class_id)
            f.append('term_id', term_id)
            f.append('year', year)

            const response = await http.post(constant.DASHBOARD_GET_WELLBEING_DATA, f)
            wellbeing_loading.value = false
            wellbeing_data.value = response.data.data
        } catch (err) {
            wellbeing_loading.value = false
        }
    }
    
    return {
        wellbeing_loading, wellbeing_data, getWellbeingData
    }
}