 <template>
    <form  ref="studentData" @submit.prevent="addNotes"  action="post"  novalidate >
                <div  class=" pt-0 mt-0">
                    <div class="form-group pb-2">
                        <label>Notes</label>
                        <textarea class="form-control" name="notes" v-model="notes"></textarea>
                    </div>
                    <div class="form-group" v-if="2 == 3">
                        <label>Attach a file</label>
                        <el-upload
                            class="upload-demo"
                            drag
                            :action="postUrl"
                            name="file"
                            :on-success="success"
                            :on-remove="handleRemove">
                            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                            <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                            </div>
                            <div class="el-upload__tip">
                                upload a csv, excel file
                            </div>
                        </el-upload>
                        <span>e.g. excel, images, docs, pdf, etc...</span>
                    </div>
                    <button type="button" :disabled="create_status" @click="notes = ''" class="cursor-pointer pull-right btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="create_status" class="btn btn-sm btn-info save pull-right mr-2">
                        <span  v-if="!create_status">  Add Note</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Adding notes...</span>
                    </button>
                    <div class="form-group pt-10">
                        <div class="d-flex align-items-center mb-6" v-for="(item, i) in notesList" :key="i">
                            <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                                <div class="d-flex flex-column align-items-cente py-2 w-75">
                                    <a href="Javascript:void(0)" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{ item.notes }}</a>
                                    <span class="text-muted font-weight-bold">{{ moment(item.created_at).format('LLL') }}</span>
                                </div>
                                <a href="Javascript:void(0)" @click="confirmDelete(item, i, $event)" class="btn btn-icon btn-light btn-sm">
								<span class="svg-icon svg-icon-danger">
                                    <span class="svg-icon svg-icon-md">
                                       <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"/>
                                                <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                                                <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                            </a>
                            </div>

                        </div>
                    </div>
                </div>
    </form>
    <ConfirmPopup></ConfirmPopup>
</template>
<script>
import { defineComponent, ref, watch, onMounted, computed, inject } from 'vue'
import { ElUpload, ElMessageBox, ElIcon }   from  'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import  NotesAdd  from '@/composables/notes/NotesAdd'
import  NotesGetList  from '@/composables/notes/NotesGetList'
import  NotesDelete  from '@/composables/notes/NotesDelete'

import moment from 'moment'
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
    components: { ElUpload, UploadFilled, ElIcon },
    props: {
        selectedStudent: { default: [] }
    },
    setup(props) {
        const {  utils, http, constants, toast,store } = inject('App')
        const { create_status, insertNotes } = NotesAdd(http, constants, toast)
        const {  notesLoading, notesList, getList } =  NotesGetList(http, constants, toast)  
        const {  deleteStatus, deleteNotes } =  NotesDelete(http, constants, toast)  

        const confirm = useConfirm();

        const display = ref(false)
        const addStatus = ref(false)
        const notes = ref('')
        const tempID = ref('')
        const studentID = ref(0)
        const postUrl = ref('')
        
        function success(response, file, file_list) {
        }
        function addNotes() {
            insertNotes(props.selectedStudent.id, notes.value)
        }
        function handleRemove(file, fileList) {
            console.log(file)
        }
        function confirmDelete(notes, i, event) {
            confirm.require({
                target: event.currentTarget,
                message: 'Do you want to delete this note?',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    deleteNotes(notes.id)
                },
                reject: () => {
                }
            });
        }

        watch(create_status, (n) => {
            if (create_status.value == false)
                getList(props.selectedStudent.id)
        })
        watch(deleteStatus, (n) => {
            if (deleteStatus.value == false)
                getList(props.selectedStudent.id)
        })
          onMounted(() => {
            notes.value = ''
            tempID.value = utils.getRandomNumbers()
            studentID.value = props.selectedStudent.id
            postUrl.value =  `${process.env.VUE_APP_API_URL}image/upload-attachment?token=${localStorage.getItem("token")}&student_id=${studentID.value}&temp_id=${tempID.value}`

            getList(props.selectedStudent.id)
         })

         return {
            addNotes,  create_status, display, success, addStatus, notes, handleRemove, tempID, studentID,
             postUrl, notesList, moment, confirmDelete

         }
    },
})
</script>
