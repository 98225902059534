import { ref } from 'vue'
export default function SurveyGetReportBySurveyID(http, constant) {
    let report_loading = ref(false)
    let survey_report = ref([])

    async function getSurveyReportBySurveyID(respondent_id) {
        report_loading.value = true
        survey_report.value = []
        try {
            const f = new URLSearchParams()
            f.append('respondent_id', respondent_id)
            const response = await http.post(constant.SURVEY_GET_REPORT_BY_SURVEY_ID, f)
            report_loading.value = false
            survey_report.value = response.data.data
        } catch (err) {
            report_loading.value = false
        }
    }
    return {
        report_loading, survey_report, getSurveyReportBySurveyID
    }
}