<template>
    <Sidebar baseZIndex="900" v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                <div class="w-100">
                    <p>Students</p>
                    <small>{{ title }}</small>

                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content ">
            <div  class="p-10   pt-0 mt-0">
                <div  v-for="(item, i) in student_list" :key="i"  @click="viewInfo(item)" class="box-shadow  rounded-lg mt-2 cursor-pointer  mb-0 pb-3 w-100 card">
                    <span class="d-flex">
                        <Avatar v-if="item.thumbnail_type == 'image'
                                        || item.thumbnail_type == 'upload' 
                                        || item.thumbnail_type == 'library' " :image="`${item.path}${item.thumbnail}`" class="p-mr-2" size="xlarge" shape="circle" />
                    <div  v-if="item.thumbnail_type == 'color'" :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>

                    <p class="ml-5 pt-4 fw-500 text-capitalize">{{item.name}}</p>
                    </span>
                </div>
            </div>
        </div>
    </Sidebar>
</template>
<script>

import { defineComponent, ref, watch, inject } from 'vue'
import Sidebar from 'primevue/sidebar';
import Avatar from 'primevue/avatar';

export default defineComponent({
    props: {
        students_list_connection_watcher: { tpe: Number, default: 1 },
        student_list: { default: [] },
        title: { type: String, default: ''}
    },
    components: { Sidebar, Avatar },
    setup(props) {
        const { utils, store, toast, http, constants } = inject('App') 
        const { viewInfo } = inject('Dashboard')
        const display = ref(false)
        
         watch(()=>props.students_list_connection_watcher, (n) => {
            display.value = true
         })

        return {
            display, viewInfo,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
        }

    },
})
</script>
