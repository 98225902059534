import { ref } from 'vue'
export default function NotesAdd(http, constant, toast) {
    const create_status = ref(false)
    async function insertNotes(student_id, notes) {
        try {
            create_status.value = true

            const f = new URLSearchParams();
            f.append('student_id', student_id)
            f.append('notes', notes)
            const response = await http.post(constant.NOTES_ADD, f)
            create_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'Notes was successfully created.', life: 3000});

        } catch (err) {
            create_status.value = false
        }
    }
    return {
        create_status,
        insertNotes
    }
}