<template>
     <Dialog :header="`Student Info`"
            :draggable="false"  :modal="true" v-model:visible="visible" :maximizable="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '70vw'}">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-5">
                <div class="d-flex">
                    <div>
                     <Avatar :image="`${selected_student.path}${selected_student.thumbnail}`" class="p-mr-2 user-p" size="large" shape="circle" />
                    </div>
                    <div>
                        <div class="d-flex align-items-center mb-2 pt-3">
                            <p  class="text-gray-900 text-hover-primary fs-20 fw-bolder  pl-3"> {{ selected_student.name.toUpperCase() }} </p>
                        </div>
                        <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" style="margin-top: -15px !important">
                            <p  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span v-if="selected_student.classes.length > 0">{{ selected_student.classes[0].name }}</span> 
                            </p>
                            <p class="d-flex align-items-center text-gray-400 pl-3 text-hover-primary me-5 mb-2">
                                <span class="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="black"></path>
                                        <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="black"></path>
                                    </svg>
                                </span>
                                {{ selected_student.email }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <el-tabs :tab-position="`left`" v-model="selectedTab" style="height: 500px">
                <el-tab-pane label="Student Details" name="1">
                    <div class="p-10 pt-0" style="    overflow: scroll;height: 500px;">
                        <div class="row mb-7 pl-3">
                            <label class="col-lg-2 fw-bold fw-600">Name</label>
                            <div class="col-lg-10">
                                <span class="fw-bolder fs-6 text-gray-800 text-capitalize">{{ selected_student.name }}</span>
                            </div>
                        </div>
                        <div class="row mb-7  pl-3">
                            <label class="col-lg-2 fw-bold fw-600">Email</label>
                            <div class="col-lg-10">
                                <span class="fw-bolder fs-6 text-gray-800">{{ selected_student.email }}</span>
                            </div>
                        </div>
                        <div class="row mb-7  pl-3">
                            <label class="col-lg-2 fw-bold fw-600">Gender</label>
                            <div class="col-lg-10">
                                <span class="fw-bolder fs-6 text-gray-800">{{ selected_student.gender }}</span>
                            </div>
                        </div>
                        <div class="row mb-7  pl-3">
                            <label class="col-lg-2 fw-bold fw-600">Age</label>
                            <div class="col-lg-10">
                                <span class="fw-bolder fs-6 text-gray-800">{{ selected_student.age }}</span>
                            </div>
                        </div>
                        <div class="row mb-7  pl-3">
                            <label class="col-lg-2 fw-bold fw-600">Birthday</label>
                            <div class="col-lg-10">
                                <span class="fw-bolder fs-6 text-gray-800">{{ selected_student.dob }}</span>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane label="Class" name="2">
                    <div  class="p-10" style="    overflow: scroll;height: 500px;">
                         <section class="pl-5 pr-5 pt-5 pb-5" >
                            <div class="mb-1 pb-2 cursor-pointer" v-for="(classes, x) in selected_student.classes" :key="x">
                                <div class="d-flex student-cardv2">
                                    <span class="pl-5 pt-3 flex pt-3 w-100">
                                        <p class="pb-0 mb-0  w-100 d-flex">
                                            <span class="w-100 text-capitalize fw-500 pb-3">{{classes.classname[0]}}</span>
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <no-results-error  v-if="selected_student.classes.length ==0" :message="`No class!`"></no-results-error>
                        </section>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Close Friends" name="3">
                    <div class="row"  style="overflow: scroll;height: 500px;">
                        <div class="col-12 col-md-6">
                            <p class="text-capitalize pl-5 fw-600">Students who selected {{ selected_student.name }}</p>
                            <div  class="p-0" v-if="student_info.who_picked_me">
                                <section class="pl-5 pr-5 pb-5" >
                                    <template v-for="(student, x) in student_info.who_picked_me" >
                                        <div class="mb-1 pb-2 cursor-pointer" v-if="student.info" :key="x">
                                            <div class="d-flex student-cardv2">
                                                <Avatar :image="`${web_url}${student.info.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" />
                                                <span class="pl-5 pt-3 flex pt-3 w-100">
                                                    <p class="pb-0 mb-0  w-100 d-flex">
                                                        <span class="w-100 text-capitalize fw-500">{{student.info.name}}</span>
                                                        <span class="pr-3">{{student.count}}</span>
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <no-results-error  v-if="student_info.who_picked_me.length ==0" :message="`No close friends!`"></no-results-error>
                                </section>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                             <p class="text-capitalize pl-5 fw-600 ">Students selected  by {{ selected_student.name }}</p>
                             <template v-if="student_info.i_picked">
                                <div  class="p-0" >
                                    <section class="pl-5 pr-5 pb-5" >
                                        <template v-for="(student, x) in student_info.i_picked">
                                            <div class="mb-1 pb-2 cursor-pointer" v-if="student.info" :key="x">
                                                <div class="d-flex student-cardv2" >
                                                    <Avatar :image="`${web_url}${student.info.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" />
                                                    <span class="pl-5 pt-3 flex pt-3 w-100">
                                                        <p class="pb-0 mb-0  w-100 d-flex">
                                                            <span class="w-100 text-capitalize fw-500">{{student.info.name}}</span>
                                                            <span class="pr-3">{{student.count}}</span>
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <no-results-error  v-if="student_info.i_picked.length ==0" :message="`No close friends!`"></no-results-error>
                                    </section>
                                </div>
                             </template>
                        </div>
                    </div>
                     <TabView>
                        <TabPanel :header="`Students who test all i can lol selected ${selected_student.name}`">
                             
                        </TabPanel>
                        <TabPanel :header="`Students selected  by ${selected_student.name}`">
                            
                        </TabPanel>
                     </TabView>
                </el-tab-pane>
                <el-tab-pane label="Survey" name="4">
                    <a href="Javascript:void(0)" v-if="survey_view == 2"  @click="survey_view = 1" style="width: 100px" >
                        <i class="pi pi-chevron-left"></i>Back
                    </a>
                    <div class="p-10" style="    overflow: scroll;height: 500px;">
                    <div v-if="survey_view == 1">
                        <div  v-for="(item, i) in student_info.survey" :key="i" @click="getSurveyReport(item)"  class="box-shadowv1 rounded-lg mt-2 cursor-pointer  mb-0  padding-10 w-100 card">
                            <div class="d-flex">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="44" height="44" rx="13" fill="#9737D0"></rect><path d="M30.3333 21.2333V22C30.3323 23.797 29.7504 25.5455 28.6744 26.9848C27.5984 28.4241 26.086 29.477 24.3628 29.9866C22.6395 30.4961 20.7977 30.4349 19.112 29.8121C17.4264 29.1894 15.9872 28.0384 15.0091 26.5309C14.031 25.0233 13.5665 23.24 13.6847 21.4469C13.8029 19.6538 14.4976 17.9469 15.6652 16.5809C16.8328 15.2148 18.4106 14.2628 20.1635 13.8668C21.9163 13.4707 23.7502 13.6519 25.3916 14.3833" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.3333 15.3333L22 23.6749L19.5 21.1749" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <div class="w-100">
                                <p class="pl-5  fs-16 d-flex">
                                        <span class="w-100">{{item.survey.name}} </span>
                                        <span style="width: 150px" class="fs-12">  {{ item.term }} / {{ item.week_no }}</span>
                                </p>
                                <p class="pl-5 fs-12 mtn-13 mb-0 d-flex">
                                    <span class="w-100"> {{item.survey.descriptions}} </span>
                                    <span  style="width: 150px"> {{moment(item.created_at).format('YYYY-MM-DD')}} </span>
                                
                                </p>
                                </div>
                            </div>
                        </div>
                        <template v-if="student_info.survey">
                            <no-results-error  v-if="student_info.survey.length ==0" :message="`No submitted survey!`"></no-results-error>
                        </template>
                    </div>
                    <div v-if="survey_view == 2">
                        <div v-for="(item, i) in survey_report.questions" :key="i" class="box-shadow mb-5 pt-5 pb-5 p-0">
                                <h4 class="pl-6 fs-14 fw-500 pr-6 pb-4"  v-if="i != 1">{{i + 1}}. {{item.question}}</h4>
                                <h4 class="pl-6 fs-14 fw-500 pr-6 pb-4" v-else> {{i + 1}}.  Is there something  <span class="text-capitalize">{{ selected_student.name }} </span> looking forward to? </h4>

                                <div  class="p-10" v-if="i == 0">
                                <section class="pl-5 pr-5 pt-5 pb-5" >
                                    <div class="mb-1 pb-3 cursor-pointer" v-for="(student, x) in item.answers" :key="x">
                                        <div class="d-flex student-cardv2 ">
                                              <Avatar v-if="student.student.thumbnail_type == 'image'
                                                                || student.student.thumbnail_type == 'upload' 
                                                                || student.student.thumbnail_type == 'library' " :image="`${student.student.path}${student.student.thumbnail}`" class="p-mr-2" size="xlarge" shape="circle" />
                                            <div  v-if="student.student.thumbnail_type == 'color'" :style="{ 'background-color': `#${student.student.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>

                                            <span class="pl-5 pt-3 flex  w-100">
                                            <p class="pb-0 mb-0 fw-500 w-100 text-capitalize">{{student.answer_text}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </section>
                                </div>
                                <div  class="p-10" v-if="i == 1">
                                    <section class="pl-5 pr-5 pb-5" v-if="i == 1">
                                        <div class="mb-1 cursor-pointer" v-for="(opt, x) in item.answers" :key="x">
                                            <div class="d-flex student-cardv2">
                                                <span class="pl-5 pb-0 pt-3 flex w-100">
                                                    <p class=" fw-500 mb-0  w-100 pb-3 pt-0">{{ opt.answer_text }}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div  class="p-10" v-if="i >= 2">
                                    <section class="pl-5 pr-5 pb-5">
                                        <div class="mb-1 cursor-pointer" v-for="(opt, x) in item.answers" :key="x">
                                            <div class="d-flex student-cardv2">
                                                <span class="pl-5 pb-0 pt-3 flex w-100">
                                                    <p class=" fw-500 mb-0  pb-3 w-100 pt-0">{{ opt.answer_text }}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                        </div>
                    </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Notes" name="5">
                    <div class="p-10">
                            <StudentsNotes  :selectedStudent="selected_student"></StudentsNotes>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="6" label="General Wellbeing Report">
                    <div class="row" style="margin-top:-20px">
                        <div class="col-12 col-sm-12 col-md-12 mb-10">
                            <div class="card card-custom">
                                <div class="card-header align-items-center border-0 mt-4">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="font-weight-bolder text-dark">General Wellbeing</span>
                                    </h3>
                                    <div class="card-toolbar">
                                        <div class="form-group mr-3">
                                            <select class="form-control" name="year">
                                                <option value="">Select</option>
                                                <option :value="2022" selected>2022</option>
                                                <option :value="2023">2023</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control" name="class"  v-model="active_term">
                                                <option value=''>Select Term</option>
                                                <option v-for="(item, i ) in defaul_term_list" :key="i" :value="item.text">{{item.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <VueApexCharts v-if="isReady" width="100%" type="area" ref="chart" :height="400" :options="optionsWellbeing" :series="seriesWellbeing"></VueApexCharts>
                                </div>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
            </el-tabs>
             <template #footer>
             </template>
    </Dialog>
    <ConfirmPopup></ConfirmPopup>
</template>
<script >
import { defineComponent, ref, watch, inject } from 'vue'
import Dialog from 'primevue/dialog';
import { ElTabs, ElTabPane } from 'element-plus'
import Avatar from 'primevue/avatar'

import  StudentsGetInfo  from '@/composables/students/StudentsGetInfo'
import  SurveyGetReportBySurveyID  from '@/composables/survey/SurveyGetReportBySurveyID'
import moment from 'moment'
import NoResultsError from '@/components/NoResultsError.vue'
import  NotesDelete  from '@/composables/notes/NotesDelete'
import { useConfirm } from "primevue/useconfirm";
import StudentsNotes from './StudentsNotes.vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import VueApexCharts from "vue3-apexcharts";
import  StudentsGetWellbeingData  from '@/composables/students/StudentsGetWellbeingData'

export default defineComponent({
    components: { VueApexCharts, Dialog, ElTabs, ElTabPane, StudentsNotes, Avatar, NoResultsError, TabView, TabPanel },
     props: {
       student_info_watcher: {
            type: Number,
            default: 1
        },
        selected_student : {
            default: []
        }
    },
    setup(props) {
        const { http, constants, toast } = inject('App')
        const confirm = useConfirm();

        const { student_info_loading, student_info, getStudentInfo } = StudentsGetInfo(http, constants)
        const { report_loading, survey_report, getSurveyReportBySurveyID } = SurveyGetReportBySurveyID(http, constants)
        const { wellbeing_loading, wellbeing_data, getWellbeingData } = StudentsGetWellbeingData(http, constants)

        const selected_respondent = ref([])
        const survey_view = ref(1)
        const visible = ref(false)
        const selectedTab = ref('1')

        const active_term = ref('')
        const defaul_term_list = ref([
            {text: 'Term 1', value: 'Term 1'},
            {text: 'Term 2', value: 'Term 2'},
            {text: 'Term 3', value: 'Term 3'},
            {text: 'Term 4', value: 'Term 4'}
        ]);
        const isReady = ref(false)

        let optionsWellbeing = ref({
                chart: {
                    id: 'vueline-example',
                    height: '100%',
                },
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: 'Fundamental Analysis of Stocks',
                    align: 'left'
                },
                subtitle: {
                    text: 'Price Movements',
                    align: 'left'
                },
                xaxis: {  
                    categories: ['True', 'False'],
                },
                grid: {
                    show: false,
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    show: true,
                    showAlways: true,
                    min: 1,
                    max: 10,
                    tickAmount: 10,
                    labels: {
                        formatter: function(v) {
                            return v.toFixed(0)
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                           return `${val}`
                        }
                    }
                },
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                legend: {
                horizontalAlign: 'left'
                }
        })

        const seriesWellbeing  = ref([])

        function getSurveyReport(r) {
            selected_respondent.value = r
            getSurveyReportBySurveyID(selected_respondent.value.id)
        }

        function changeTab() {
            console.log(1)
        }

        watch(report_loading, (n, o) => {
            if (report_loading.value == false) {
                 survey_view.value = 2
            }
        });
        
        watch(()=> (props.student_info_watcher) , (n) => {
            visible.value = true
            survey_view.value = 1
            getStudentInfo(props.selected_student.id)
            getWellbeingData(0, active_term.value, props.selected_student.id)
            selectedTab.value = '1'
            isReady.value = false
        })

        watch(active_term, (n, o) => {
            getWellbeingData(0, active_term.value, props.selected_student.id)
        });

        watch(wellbeing_loading, (n, o) => {
            if (wellbeing_loading.value == false) {
                seriesWellbeing.value = wellbeing_data.value.series
                optionsWellbeing.value = {
                    ...optionsWellbeing.value,
                    chart: {
                        id:  props.selected_student.name
                    },
                    title: {
                        text: props.selected_student.name.toUpperCase()
                    },
                    subtitle: {
                        text: active_term.value
                    },
                    xaxis: {
                        categories: wellbeing_data.value.categories
                    }
                }   
            }

            setTimeout(() => {
                isReady.value = true
            }, 500);
        });

        return  {
            visible, student_info, getSurveyReport, survey_view, survey_report,
            moment, 
            web_url: `${process.env.VUE_APP_API_SITE}${constants.STUDENTS_THUMBNAIL_PATH}`,
            active_term,
            defaul_term_list,
            optionsWellbeing,
            seriesWellbeing,
            changeTab,
            selectedTab,
            isReady
        }
    },
})
</script>
