<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="card card-custom  bg-none shadow-none p-0 card-stretch gutter-b mb-0 ">
                            <div class="card-body  p-0 d-flex align-items-center py-0 mt-0 mb-0">
                                <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                    <a href="Javascript:void(0)"
                                        class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-2 text-hover-primary">
                                        Children
                                    </a>
                                    <span class="font-weight-bold text-muted font-size-h2">
                                        {{ dashboard_data.students }}
                                    </span>
                                </div>
                                <span class="svg-icon svg-icon-9x svg-icon-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <path
                                                d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                            <path
                                                d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                fill="#000000" fill-rule="nonzero"></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            <div class="row  pt-5  pb-0">
                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="card card-custom   card-stretch gutter-b">
                                        <div class="card-body d-flex align-items-center py-0 mt-8">
                                            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                                <a href="Javascript:void(0)" @click="routeTo('Students')"
                                                    class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Male</a>
                                                <span class="font-weight-bold text-muted font-size-h2">{{
                                                dashboard_data.male }}</span>
                                            </div>
                                            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                                <a href="Javascript:void(0)" @click="routeTo('Students')"
                                                    class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Female</a>
                                                <span class="font-weight-bold text-muted font-size-h2">{{
                                                dashboard_data.female }}</span>
                                            </div>
                                            <img src="@/assets/themes/assets/media/svg/avatars/001-boy.svg" alt=""
                                                class="align-self-end h-100px">
                                            <img src="@/assets/themes/assets/media/svg/avatars/014-girl-7.svg" alt=""
                                                class="align-self-end h-100px">

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6">
                                    <div class="card card-custom card-stretch gutter-b">
                                        <div class="card-body d-flex align-items-center py-0 mt-8">
                                            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                                <a href="Javascript:void(0)" @click="viewAll"
                                                    class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Missing
                                                    Connections</a>
                                                <span v-if="missing_data.student_list"
                                                    class="font-weight-bold text-muted font-size-h2">{{
                                                    missing_data.student_list.length }}</span>
                                            </div>
                                            <template v-if="missing_data.student_list">
                                                <AvatarGroup class="mb-3" v-if="missing_data.student_list.length > 0">
                                                    <template v-for="(item, x) in missing_data.student_list" :key="x">
                                                        <template v-if="x <= 4">
                                                            <Avatar @click="viewInfo(item)" placeholder="Top"
                                                                v-tooltip.top="`${item.name}`" v-if="item.thumbnail_type == 'image'
                                                                || item.thumbnail_type == 'upload' 
                                                                || item.thumbnail_type == 'library' "
                                                                :image="`${item.path}${item.thumbnail}`"
                                                                class="p-mr-2 cursor-pointer bordered-image"
                                                                size="xlarge" shape="circle" />
                                                            <div class="cursor-pointer" @click="viewInfo(item)"
                                                                placeholder="Top" v-tooltip.top="`${item.name}`"
                                                                v-if="item.thumbnail_type == 'color'"
                                                                :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '50px', width: '50px', 'border-radius': '50%'}">
                                                            </div>
                                                        </template>
                                                        <template v-if="x >=5 && x <= 8">
                                                            <Avatar @click="viewInfo(item)" placeholder="Top"
                                                                v-tooltip.top="`${item.name}`" v-if="item.thumbnail_type == 'image'
                                                                || item.thumbnail_type == 'upload' 
                                                                || item.thumbnail_type == 'library' "
                                                                :image="`${item.path}${item.thumbnail}`"
                                                                class="p-mr-2 cursor-pointer bordered-image"
                                                                size="large" shape="circle" />
                                                            <div class="cursor-pointer" @click="viewInfo(item)"
                                                                placeholder="Top" v-tooltip.top="`${item.name}`"
                                                                v-if="item.thumbnail_type == 'color'"
                                                                :style="{ 'background-color': `#${item.thumbnail_bg}`, height: '50px', width: '50px', 'border-radius': '50%'}">
                                                            </div>
                                                        </template>
                                                    </template>
                                                    <Avatar :label="`+...`" @click="viewAll" placeholder="Top"
                                                        v-tooltip.top="'View More'" class="cursor-pointer"
                                                        shape="circle" size="large"
                                                        style="background-color:#9c27b0; color: #ffffff" />
                                                </AvatarGroup>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:-20px">
                    <div class="col-12 col-sm-12 col-md-12 mb-10">
                        <div class="card card-custom">
                            <div class="card-header align-items-center border-0 mt-4">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="font-weight-bolder text-dark">General Wellbeing</span>
                                </h3>
                                <div class="card-toolbar">
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="year" v-model="activeYear">
                                            <option :value="2022">2022</option>
                                            <option :value="2023" selected>2023</option>
                                            <option :value="2024">2024</option>
                                        </select>
                                    </div>
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="class" v-model="selected_class">
                                            <option value="0">All</option>
                                            <option v-for="(item, i ) in class_list" :key="i" :value="item">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" name="class" v-model="active_term">
                                            <option :value="`All Term`">All Term</option>
                                            <option v-for="(item, i ) in defaul_term_list" :key="i" :value="item.text">
                                                {{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body  ">
                                <VueApexCharts width="100%" type="area" ref="chart" :height="400"
                                    :options="optionsWellbeing" :series="seriesWellbeing"></VueApexCharts>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6">
                        <div class="card card-custom">
                            <div class="card-header align-items-center border-0 mt-4">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="font-weight-bolder text-dark">Future Focus</span>
                                </h3>
                                <div class="card-toolbar">
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="year" v-model="activeYear">
                                            <option :value="2022">2022</option>
                                            <option :value="2023" selected>2023</option>
                                            <option :value="2024">2024</option>
                                        </select>
                                    </div>
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="class" v-model="selected_class">
                                            <option v-for="(item, i ) in class_list" :key="i" :value="item">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" name="class" v-model="active_term">
                                            <option v-for="(item, i ) in defaul_term_list" :key="i" :value="item.text">
                                                {{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body  ">
                                <VueApexCharts width="100%" type="bar" ref="chart" :height="400" :options="options"
                                    :series="series"></VueApexCharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 ">
                        <div class="card card-custom">
                            <div class="card-header align-items-center border-0 mt-4">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="font-weight-bolder text-dark">Safety Questions</span>
                                </h3>
                                <div class="card-toolbar">
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="year" v-model="activeYear">
                                            <option :value="2022">2022</option>
                                            <option :value="2023" selected>2023</option>
                                            <option :value="2024">2024</option>
                                        </select>
                                    </div>
                                    <div class="form-group mr-3">
                                        <select class="form-control" name="class" v-model="selected_class">
                                            <option v-for="(item, i ) in class_list" :key="i" :value="item">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" name="class" v-model="active_term">
                                            <option v-for="(item, i ) in defaul_term_list" :key="i" :value="item.text">
                                                {{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body  ">
                                <VueApexCharts width="100%" type="bar" ref="chart" :height="400"
                                    :options="options_three" :series="series_three"></VueApexCharts>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 pt-5">
                    <div class="col-sm-12 co-12 col-md-3" v-if="user.user_type == 'school'">
                        <div class="card card-custom bg-light-success gutter-b">
                            <div class="card-body">
                                <span class="svg-icon svg-icon-success svg-icon-2x">
                                    <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Communication/Add-user.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <path
                                                d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                            <path
                                                d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                fill="#000000" fill-rule="nonzero" />
                                        </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>
                                <div class="text-success font-weight-bolder font-size-h2 mt-3">{{ dashboard_data.teacher
                                }} </div>
                                <a href="Javascript:void(0)" @click="routeTo('Teachers')"
                                    class="text-success font-weight-bold font-size-lg mt-1">Teachers</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 co-12 col-md-3">
                        <div class="card card-custom bg-light-primary gutter-b" style="height: 150px">
                            <div class="card-body">
                                <span class="svg-icon svg-icon-primary svg-icon-2x"><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <path
                                                d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                                fill="#000000" fill-rule="nonzero" />
                                            <path
                                                d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                                fill="#000000" opacity="0.3" />
                                        </g>
                                    </svg></span>
                                <div class="text-primary font-weight-bolder font-size-h2 mt-3">{{ dashboard_data.class
                                }}</div>
                                <a href="Javascript:void(0)" @click="routeTo('Class')"
                                    class="text-primary font-weight-bold font-size-lg mt-1">Class</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 co-12 col-md-3">
                        <div class="card card-custom bg-light-info gutter-b" style="height: 150px">
                            <div class="card-body">
                                <span class="svg-icon svg-icon-info svg-icon-2x">
                                    <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Devices/Server.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path
                                                d="M5,2 L19,2 C20.1045695,2 21,2.8954305 21,4 L21,6 C21,7.1045695 20.1045695,8 19,8 L5,8 C3.8954305,8 3,7.1045695 3,6 L3,4 C3,2.8954305 3.8954305,2 5,2 Z M11,4 C10.4477153,4 10,4.44771525 10,5 C10,5.55228475 10.4477153,6 11,6 L16,6 C16.5522847,6 17,5.55228475 17,5 C17,4.44771525 16.5522847,4 16,4 L11,4 Z M7,6 C7.55228475,6 8,5.55228475 8,5 C8,4.44771525 7.55228475,4 7,4 C6.44771525,4 6,4.44771525 6,5 C6,5.55228475 6.44771525,6 7,6 Z"
                                                fill="#000000" opacity="0.3" />
                                            <path
                                                d="M5,9 L19,9 C20.1045695,9 21,9.8954305 21,11 L21,13 C21,14.1045695 20.1045695,15 19,15 L5,15 C3.8954305,15 3,14.1045695 3,13 L3,11 C3,9.8954305 3.8954305,9 5,9 Z M11,11 C10.4477153,11 10,11.4477153 10,12 C10,12.5522847 10.4477153,13 11,13 L16,13 C16.5522847,13 17,12.5522847 17,12 C17,11.4477153 16.5522847,11 16,11 L11,11 Z M7,13 C7.55228475,13 8,12.5522847 8,12 C8,11.4477153 7.55228475,11 7,11 C6.44771525,11 6,11.4477153 6,12 C6,12.5522847 6.44771525,13 7,13 Z"
                                                fill="#000000" />
                                            <path
                                                d="M5,16 L19,16 C20.1045695,16 21,16.8954305 21,18 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,18 C3,16.8954305 3.8954305,16 5,16 Z M11,18 C10.4477153,18 10,18.4477153 10,19 C10,19.5522847 10.4477153,20 11,20 L16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 L11,18 Z M7,20 C7.55228475,20 8,19.5522847 8,19 C8,18.4477153 7.55228475,18 7,18 C6.44771525,18 6,18.4477153 6,19 C6,19.5522847 6.44771525,20 7,20 Z"
                                                fill="#000000" />
                                        </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>
                                <div class="text-info font-weight-bolder font-size-h2 mt-3">{{ dashboard_data.questions
                                }}</div>
                                <a href="Javascript:void(0)" @click="routeTo('Questions')"
                                    class="text-info font-weight-bold font-size-lg mt-1">Questions</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 co-12 col-md-3">
                        <div class="card card-custom bg-light-warning gutter-b" style="height: 150px">
                            <div class="card-body">
                                <span class="svg-icon svg-icon-warning svg-icon-2x">
                                    <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Communication/Chat-check.svg--><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path
                                                d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z"
                                                fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                            <path
                                                d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z"
                                                fill="#000000" />
                                        </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>`
                                <div class="text-warning font-weight-bolder font-size-h2 mt-3">{{ dashboard_data.survey
                                }}</div>
                                <a href="Javascript:void(0)" @click="routeTo('Survey')"
                                    class="text-warning font-weight-bold font-size-lg mt-1">Survey</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <StudentsInfo :student_info_watcher="student_info_watcher" :selected_student="selected_student"></StudentsInfo>
    <StudentsConnections :students_list_connection_watcher="students_list_connection_watcher"
        :student_list="missing_data.student_list" :title="`Student list with no connections`"></StudentsConnections>
</template>
<script>
import { defineComponent, inject, onMounted, computed, ref, provide, reactive, watch } from 'vue'
import DashboardGetData from '@/composables/dashboard/DashboardGetData'
import DashboardGetChartData from '@/composables/dashboard/DashboardGetChartData'
import DashboardGetMissingConnections from '@/composables/dashboard/DashboardGetMissingConnections'
import DashboardGetWellbeingData from '@/composables/dashboard/DashboardGetWellbeingData'


import VueApexCharts from "vue3-apexcharts";
import ClassGetList from '@/composables/class/ClassGetList'
import { ElDatePicker } from 'element-plus'
import AvatarGroup from 'primevue/avatargroup';
import Avatar from 'primevue/avatar';
import StudentsInfo from '../students/StudentsInfo.vue'
import StudentsConnections from '../students/StudentsConnections.vue'
import YearGetList from '@/composables/year/YearGetList'

export default defineComponent({
    components: { Avatar, StudentsConnections, AvatarGroup, VueApexCharts, StudentsInfo },
    setup() {
        const { appRouter, route, router, constants, http, store } = inject('App')

        const { dashboard_loading, dashboard_data, getDashboardData } = DashboardGetData(http, constants)
        const { chart_data_loading, chart_data, getDashboardChartData } = DashboardGetChartData(http, constants)
        const { missing_loading, missing_data, getMissingConnections } = DashboardGetMissingConnections(http, constants)
        const { wellbeing_loading, wellbeing_data, getWellbeingData } = DashboardGetWellbeingData(http, constants)
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)

        const { yearList, getList } = YearGetList(http, constants, store)

        const selected_class = ref([])
        const selected_week = ref('')
        const student_info_watcher = ref(1)
        const selected_student = ref([])
        const students_list_connection_watcher = ref(1)

        let options = ref({
            chart: {
                id: 'futurefocus',
                toolbar: {
                    show: true
                },
                height: '100%',
            },
            labels: {
                show: true
            },
            title: {
                text: 'Future Focus',
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'center'
            },
            xaxis: {
                type: 'category',
                categories: [1991, 1992]
            },
            yaxis: {
                labels: {
                    formatter: (value) => { return `${value}   ` }
                }
            },
            stroke: {
                curve: 'straight',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                    endingShape: 'rounded'
                },
            },
            grid: {
                show: false,
                lines: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
        })

        let options_three = ref({
            chart: {
                id: 'safetyquestions',
                toolbar: {
                    show: true
                },
                height: '100%',
            },
            labels: {
                show: true
            },
            title: {
                text: 'Safety Questions',
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'center'
            },
            xaxis: {
                type: 'category',
                categories: [1991, 1992]
            },
            yaxis: {
                labels: {
                    formatter: (value) => { return `${value}   ` }
                }
            },
            grid: {
                show: false,
                lines: {
                    show: false
                }
            },
            stroke: {
                curve: 'straight',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
        })
        let optionsWellbeing = ref({
            chart: {
                id: 'generalwellbeing',
                toolbar: {
                    show: true
                },
                height: '100%',
            },
            title: {
                text: 'General Wellbeing',
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'center'
            },
            xaxis: {
                categories: ['True', 'False'],
            },
            grid: {
                show: false,
                lines: {
                    show: false
                }
            },
            yaxis: {
                show: true,
                showAlways: true,
                min: 1,
                max: 10,
                tickAmount: 10,
                labels: {
                    formatter: function (v) {
                        return v.toFixed(0)
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${val}`
                    }
                }
            },
            stroke: {
                curve: 'straight',
                width: 2
            }
        })


        const series = ref([])
        const series_three = ref([])
        const seriesWellbeing = ref([])

        const chart = ref()
        const activeYear = ref()

        const active_term = ref('Term 1')
        const defaul_term_list = ref([
            { text: 'Term 1', value: 'Term 1' },
            { text: 'Term 2', value: 'Term 2' },
            { text: 'Term 3', value: 'Term 3' },
            { text: 'Term 4', value: 'Term 4' }
        ]);

        function viewInfo(item) {
            selected_student.value = item
            student_info_watcher.value++
        }

        function viewAll() {
            students_list_connection_watcher.value++
        }

        function routeTo(n) {
            appRouter.routeTo(route, router, n)
        }

        watch(class_loading, (n, o) => {
            if (class_loading.value == false) {
                if (class_list.value.length > 2)
                    selected_class.value = class_list.value[2]
                else if (class_list.value.length > 0 && class_list.value.length <= 2)
                    selected_class.value = class_list.value[0]
            }
        });

        watch(active_term, (n, o) => {
            getDashboardChartData(selected_class.value.id, active_term.value, activeYear.value)
            getWellbeingData(selected_class.value.id, active_term.value, activeYear.value)
        });

        watch(selected_class, (n, o) => {
            getDashboardChartData(selected_class.value.id, active_term.value, activeYear.value)
            getWellbeingData(selected_class.value.id, active_term.value, activeYear.value)
        });

        watch(chart_data_loading, (n, o) => {
            if (chart_data_loading.value == false) {
                series.value = chart_data.value.series
                series_three.value = chart_data.value.question_three_series
                options.value = {
                    ...options.value,
                    subtitle: {
                        text: selected_class.value.name + ' / ' + active_term.value
                    },
                    xaxis: {
                        categories: chart_data.value.categories
                    }
                }
                options_three.value = {
                    ...options_three.value,
                    subtitle: {
                        text: selected_class.value.name + ' / ' + active_term.value
                    },
                    xaxis: {
                        categories: chart_data.value.categories
                    }
                }
            }
        });

        watch(activeYear, (n, o) => {
            getDashboardChartData(selected_class.value.id, active_term.value, activeYear.value)
            getWellbeingData(selected_class.value.id, active_term.value, activeYear.value)
        });

        watch(wellbeing_loading, (n, o) => {
            if (wellbeing_loading.value == false) {
                seriesWellbeing.value = wellbeing_data.value.series
                optionsWellbeing.value = {
                    ...optionsWellbeing.value,
                    subtitle: {
                        text: selected_class.value.name + ' / ' + active_term.value
                    },
                    xaxis: {
                        categories: wellbeing_data.value.categories
                    }
                }
            }
        });


        onMounted(async () => {
            await getList()

            activeYear.value = store.getters['YearsModule/GET_ACTIVE_YEAR']

            getDashboardData()
            getMissingConnections()
            getClassList()
        })

        provide('Dashboard', { viewInfo })

        return {
            dashboard_data, routeTo, options, series, class_list, selected_class, selected_week,
            active_term, defaul_term_list, chart, series_three, options_three, chart_data, chart_data_loading,
            user: computed(() => store.getters['UserModule/GET_USER']), selected_student, student_info_watcher,
            viewInfo, viewAll, students_list_connection_watcher, missing_data, optionsWellbeing, seriesWellbeing,
            activeYear
        }
    },
})
</script>
<style scoped>
.bordered-image {
    border: 1px solid #DDD;
}
</style>