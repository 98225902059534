import { ref } from 'vue'
export default function NotesGetList(http, constant, toast) {
    const notesLoading = ref(false)
    const notesList = ref(false)
    async function getList(student_id) {
        try {
            notesLoading.value = true

            const f = new URLSearchParams();
            f.append('student_id', student_id)
            const response = await http.post(constant.NOTES_GET_LIST, f)
            const { status, data } = response.data
            notesList.value  = data

        } catch (err) {
            notesLoading.value = false
        }
    }
    return {
        notesLoading,
        notesList,
        getList
    }
}