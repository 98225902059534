import { ref } from 'vue'
export default function DashboardGetData(http, constant, store) {
    let dashboard_loading = ref(false)
    let dashboard_data = ref([])

    async function getDashboardData() {
        dashboard_loading.value = true;
        dashboard_data.value = []
        try {
            const response = await http.post(constant.DASHBOARD_GET_DATA, [])
            dashboard_loading.value = false
            dashboard_data.value = response.data.data
        } catch (err) {
            dashboard_loading.value = false;
        }
    }
    
    return {
        dashboard_loading, dashboard_data, getDashboardData
    }
}