import { ref } from 'vue'
export default function NotesDelete(http, constant, toast) {
    const deleteStatus = ref(false)
    async function deleteNotes(id) {
        try {
            deleteStatus.value = true

            const f = new URLSearchParams();
            f.append('id', id)
            const response = await http.post(constant.NOTES_DELETE, f)
            const { status, data } = response.data
            
            toast.add({severity:'success', summary: 'Success', detail:'Notes was successfully deleted.', life: 3000});

            deleteStatus.value = false
        } catch (err) {
            deleteStatus.value = false
        }
    }
    return {
        deleteStatus,
        deleteNotes
    }
}