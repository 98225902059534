import { ref } from 'vue'
export default function DashboardGetMissingConnections(http, constant) {
    let missing_loading = ref(false)
    let missing_data = ref([])

    async function getMissingConnections() {
        missing_loading.value = true;
        missing_data.value = []
        try {
            const response = await http.post(constant.DASHBOARD_GET_MISSING_CONNECTIONS, [])
            missing_loading.value = false
            missing_data.value = response.data.data
            console.log(chart_data)
        } catch (err) {
            missing_loading.value = false
        }
    }
    
    return {
        missing_loading, missing_data, getMissingConnections
    }
}