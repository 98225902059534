import { ref } from 'vue'
export default function StudentsGetInfo(http, constant, store) {
    let student_info_loading = ref(false)
    let student_info = ref([])

    async function getStudentInfo(student_id) {
        student_info_loading.value = true
        student_info.value = []
        try {
            const f = new URLSearchParams()
            f.append('student_id', student_id)

            const response = await http.post(constant.STUDENT_GET_INFO, f)
            student_info_loading.value = false
            student_info.value = response.data.data
        } catch (err) {
            student_info_loading.value = false
        }
    }
    return {
        student_info_loading, student_info, getStudentInfo
    }
}